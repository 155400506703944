import React from "react";
import "./RestaurantCard.css";

const RestaurantCard = ({ restaurant, onClick }) => {

  const handleClick = () => {
    if (onClick) {
      onClick(restaurant);
    }
  };

  return (
    <div className="list-restaurant-card" onClick={handleClick}>
      <div className="spacing">
      <div className="list-restaurant-info">
        <h2>
          {restaurant.number}. {restaurant.name}
        </h2>
        <p>{restaurant.tagline}</p>
      </div>
      <div className="list-restaurant-icon">
        <img
          className="icons"
          alt={restaurant.name}
          src={`../images/icons/${restaurant.number}.png`}
        ></img>{" "}
      </div>
      </div>
    </div>
  );
};

export default RestaurantCard;
