import React, { useState, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  ZoomControl,
  useMapEvent,
} from "react-leaflet";
import remarkGfm from 'remark-gfm';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import RestaurantList from "./components/RestaurantList";
import restaurantsData from "./restaurants.json";
import homeData from "./home.json";
import "./App.css";
import { icon } from "leaflet";
import useIsMobile from "./useIsMobile";
import getEmbedUrl from "./getEmbedUrl";
import ReactMarkdown from "react-markdown";
import "plyr/dist/plyr.css";

const App = () => {
  const isMobile = useIsMobile();

  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [showHomeScreen, setShowHomeScreen] = useState(true);
  const [showRestaurantList, setShowRestaurantList] = useState(false);
  const [zoom, setZoom] = useState(isMobile ? 3 : 4);
  const [previousView, setPreviousView] = useState("map"); 
  const navigate = useNavigate();
  const { restaurantId } = useParams();
  const location = useLocation();

  const formatNameForUrl = (name) => {
    return encodeURIComponent(
      name
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(/&/g, "and")
    );
  };
    
  
  useEffect(() => {
    if (restaurantId) {
      const restaurant = restaurantsData.find(
        (r) => decodeURIComponent(formatNameForUrl(r.name)) === restaurantId
      );
      console.log("Matched Restaurant: ", restaurant);
      if (restaurant) {
        setSelectedRestaurant(restaurant);
        setShowHomeScreen(false);
        setShowRestaurantList(false);
      }
    } else if (location.pathname === "/list") {
      setSelectedRestaurant(null);
      setShowHomeScreen(false);
      setShowRestaurantList(true);
      setPreviousView("list");

    }
    else if (location.pathname === "/map") {
      setSelectedRestaurant(null);
      setShowHomeScreen(false);
      setShowRestaurantList(false);
      setPreviousView("map");
    } 
    else {
      setSelectedRestaurant(null);
      setShowHomeScreen(true);
      setShowRestaurantList(false);
    }
  }, [restaurantId, location.pathname]);


  const handleMarkerClick = (restaurant) => {
    setSelectedRestaurant(restaurant);
    setShowHomeScreen(false);
    navigate(`/${formatNameForUrl(restaurant.name)}`);
  };

  const handleCMMIconClick = () => {
    window.open("https://www.calmigration.org/", "_blank");
  };

  const handleHomeClick = () => {
    setSelectedRestaurant(null);
    setShowHomeScreen(true);
    navigate("/");
  };

  const handleBackClick = () => {
    setSelectedRestaurant(null);
    setShowHomeScreen(false);
    if (previousView === "list") {
      setShowRestaurantList(true);
      navigate("/list");
    } else {
      setShowRestaurantList(false);
      navigate("/map");
    }
  };

  const handleStartClick = () => {
    setSelectedRestaurant(null);
    setShowHomeScreen(false);
    setShowRestaurantList(false);
    navigate("/map");
  };

  const toggleRestaurantList = () => {
    setShowRestaurantList(true);
    setSelectedRestaurant(null);
    navigate("/list");
  };

  const iconSize =
    zoom === 6
      ? [82, 82]
      : zoom === 5
      ? [72, 72]
      : zoom === 4
      ? [52, 52]
      : zoom === 3
      ? [35, 35]
      : zoom === 2
      ? [22, 22]
      : [52, 52];

  const cmmSize =
    zoom === 6
      ? [112, 112]
      : zoom === 5
      ? [102, 102]
      : zoom === 4
      ? [82, 82]
      : zoom === 3
      ? [65, 65]
      : zoom === 2
      ? [52, 52]
      : [82, 82];

  const ICON = (number) =>
    icon({
      iconUrl: `../images/${number}.png`,
      iconSize: iconSize,
      iconAnchor: [32 / 2, 32],
    });

  const cmmIcon = icon({
    iconUrl: "../images/cmm_map.png",
    iconSize: cmmSize,
    iconAnchor: [25, 50],
    className: "cmmIcon",
  });

  const MapEvents = () => {
    useMapEvent("zoomend", (event) => {
      const zoom = event.target.getZoom();
      setZoom(zoom);
    });
    return null;
  };

  const southWest = [-55, -140];
  const northEast = [65, 65];
  const bounds = [southWest, northEast];

  const getEmbedUrlWithParams = (url) => {
    const embedUrl = getEmbedUrl(url);
    return `${embedUrl}?origin=${window.location.origin}&iv_load_policy=3&modestbranding=1&playsinline=1&showinfo=0&rel=0&color=white`;
  };

  return (
    <div className="whole-project">
      <Helmet>
        <title>
          {selectedRestaurant
            ? `${selectedRestaurant.number}. ${selectedRestaurant.name} | Melting Spots`
            : "Melting Spots: An Immigrant Map of San Francisco Food"}
        </title>
        <meta
          property="og:title"
          content={
            selectedRestaurant
              ? `${selectedRestaurant.number}. ${selectedRestaurant.name} | Melting Spots`
              : "Melting Spots: An Immigrant Map of San Francisco Food"
          }
        />
      </Helmet>
      <MapContainer
        center={isMobile ? [5, 40] : [10, 0]}
        zoom={isMobile ? 3 : 4}
        minZoom={3}
        maxZoom={6}
        scrollWheelZoom={true}
        zoomControl={false}
        maxBounds={bounds}
      >
        <TileLayer
          attribution="its offline"
          url={"../media/png-small/{z}_{x}_{y}.png"}
        />
        <MapEvents />
        {restaurantsData.map((restaurant, index) => {
          let pressTimer;

          const handleMouseDown = (e) => {
            pressTimer = setTimeout(() => {
              // Add the pulse effect on long press
              if (e.target instanceof HTMLElement) {
                e.target.classList.add("pulse");
              }
            }, 500);
          };

          const handleMouseUp = (e) => {
            clearTimeout(pressTimer);
            // Remove the pulse effect on mouse up
            if (e.target instanceof HTMLElement) {
              e.target.classList.remove("pulse");
            }
          };

          const handleClick = (e) => {
            clearTimeout(pressTimer);
            handleMarkerClick(restaurant);
          };

          return (
            <Marker
              key={index}
              position={restaurant.position}
              icon={ICON(restaurant.number)}
              eventHandlers={{
                click: handleClick,
                mousedown: handleMouseDown,
                mouseup: handleMouseUp,
                mouseleave: handleMouseUp,
                touchstart: handleMouseDown,
                touchend: handleMouseUp,
                touchcancel: handleMouseUp,
              }}
            />
          );
        })}

        <Marker
          position={[35, 55]}
          icon={cmmIcon}
          eventHandlers={{
            click: handleCMMIconClick,
          }}
        ></Marker>

        <ZoomControl position="bottomright" />
      </MapContainer>

      {showHomeScreen && <div className="home-screen-overlay"></div>}
      {showHomeScreen && <div className="map-overlay"></div>}

      {showHomeScreen ? (
        homeData.map((home, index) => (
          <div className="home-panel" key={index}>
            <div className="logo">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.calmigration.org/"
              >
                <img
                  className="calmigration-logo"
                  src="../images/calmigration.svg"
                  alt="Calmigration Museum"
                />
              </a>
            </div>
            <div className="home-screen">
              <div className="melting">
                <img
                  src={
                    isMobile
                      ? "../images/melting-stacked.png"
                      : "../images/melting-wide.png"
                  }
                  alt="Melting Spots"
                />
              </div>
              {isMobile ? (
                <>
                  <div className="plate">
                    <img
                      className="plate-img"
                      src="../images/bowl-mobile.png"
                      alt="plate"
                    />
                  </div>
                  <div className="home-main-content">
                    <div className="home-text">
                      <img
                        src="../images/tagline.png"
                        alt="An immigrant map of San Fransicso Food"
                      />
                      <p>
                      <ReactMarkdown remarkPlugins={[remarkGfm]}>{home.content}</ReactMarkdown>
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <div className="home-main-content">
                  <div className="home-text">
                    <img
                      src="../images/tagline.png"
                      alt="An immigrant map of San Fransicso Food"
                    />
                    <p>
                     <ReactMarkdown remarkPlugins={[remarkGfm]}>{home.content}</ReactMarkdown>
                    </p>
                  </div>
                  <div className="plate">
                    <img
                      className="plate-img"
                      src="../images/bowl-desktop.png"
                      alt="plate"
                    />
                  </div>
                </div>
              )}
              <div className="start-button">
                <button onClick={handleStartClick}>
                  EXPLORE{" "}
                  <img
                    alt=">"
                    src="../images/arrow-right.svg"
                    className="right-arrow"
                  ></img>
                </button>
              </div>
            </div>
            <div className="bottom-text"><ReactMarkdown remarkPlugins={[remarkGfm]}>{home.filler_text}</ReactMarkdown></div>
          </div>
        ))
      ) : selectedRestaurant ? (
        <div className="side-panel">
          <div className="restaurant-info-wrapper">
          <div className="restaurant-info">
          <div className="green-background"></div>
            <div className="detail-logo">
              <div className="logo-container" onClick={handleHomeClick}>
                <img
                  className="icon-img"
                  alt="melting-spots"
                  src="../images/melting-wide.png"
                ></img>
              </div>
            </div>
            <div className="padding">
              <p className="restaurant-name">
                {selectedRestaurant.number}. {selectedRestaurant.name}
              </p>
              <p className="restaurant-tagline">{selectedRestaurant.tagline}</p>
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  style={{ aspectRatio: "16/9" }}
                  src={
                    selectedRestaurant.audio_player_embed
                      ? getEmbedUrlWithParams(
                          selectedRestaurant.audio_player_embed
                        )
                      : "https://www.youtube.com/embed/SmSu2meBR2I?si=GnGoPee-3jrZDQgS"
                  }
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  color="white"
                ></iframe>
              </div>

              <p className="restaurant-description">
                <ReactMarkdown>{selectedRestaurant.content}</ReactMarkdown>
              </p>
              <p>
                <a
                  href={selectedRestaurant.map_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {selectedRestaurant.address}
                </a>
                <br />
                {selectedRestaurant.social_handle ? (
                  <a
                    href={`https://www.instagram.com/${selectedRestaurant.social_handle}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      id="Layer_1"
                      data-name="Layer 1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1000 1000"
                    >
                      <path
                        d="M295.42,6c-53.2,2.51-89.53,11-121.29,23.48-32.87,12.81-60.73,30-88.45,57.82S40.89,143,28.17,175.92c-12.31,31.83-20.65,68.19-23,121.42S2.3,367.68,2.56,503.46,3.42,656.26,6,709.6c2.54,53.19,11,89.51,23.48,121.28,12.83,32.87,30,60.72,57.83,88.45S143,964.09,176,976.83c31.8,12.29,68.17,20.67,121.39,23s70.35,2.87,206.09,2.61,152.83-.86,206.16-3.39S799.1,988,830.88,975.58c32.87-12.86,60.74-30,88.45-57.84S964.1,862,976.81,829.06c12.32-31.8,20.69-68.17,23-121.35,2.33-53.37,2.88-70.41,2.62-206.17s-.87-152.78-3.4-206.1-11-89.53-23.47-121.32c-12.85-32.87-30-60.7-57.82-88.45S862,40.87,829.07,28.19c-31.82-12.31-68.17-20.7-121.39-23S637.33,2.3,501.54,2.56,348.75,3.4,295.42,6m5.84,903.88c-48.75-2.12-75.22-10.22-92.86-17-23.36-9-40-19.88-57.58-37.29s-28.38-34.11-37.5-57.42c-6.85-17.64-15.1-44.08-17.38-92.83-2.48-52.69-3-68.51-3.29-202s.22-149.29,2.53-202c2.08-48.71,10.23-75.21,17-92.84,9-23.39,19.84-40,37.29-57.57s34.1-28.39,57.43-37.51c17.62-6.88,44.06-15.06,92.79-17.38,52.73-2.5,68.53-3,202-3.29s149.31.21,202.06,2.53c48.71,2.12,75.22,10.19,92.83,17,23.37,9,40,19.81,57.57,37.29s28.4,34.07,37.52,57.45c6.89,17.57,15.07,44,17.37,92.76,2.51,52.73,3.08,68.54,3.32,202s-.23,149.31-2.54,202c-2.13,48.75-10.21,75.23-17,92.89-9,23.35-19.85,40-37.31,57.56s-34.09,28.38-57.43,37.5c-17.6,6.87-44.07,15.07-92.76,17.39-52.73,2.48-68.53,3-202.05,3.29s-149.27-.25-202-2.53m407.6-674.61a60,60,0,1,0,59.88-60.1,60,60,0,0,0-59.88,60.1M245.77,503c.28,141.8,115.44,256.49,257.21,256.22S759.52,643.8,759.25,502,643.79,245.48,502,245.76,245.5,361.22,245.77,503m90.06-.18a166.67,166.67,0,1,1,167,166.34,166.65,166.65,0,0,1-167-166.34"
                        transform="translate(-2.5 -2.5)"
                      />
                    </svg>

                    {selectedRestaurant.social_handle}
                  </a>
                ) : null}
              </p>
            </div>
          </div>
          </div>
          <div className="back-button">
                <button onClick={handleBackClick}>
                  <img
                    alt=">"
                    src="../images/arrow-left.svg"
                    className="right-arrow"
                  ></img>{" "}
                  BACK
                </button>
              </div>
        </div>
      ) : null}

      {!showHomeScreen && selectedRestaurant === null ? (
        <div
          className={`restaurant-list-container ${
            showRestaurantList ? "show" : "hide"
          }`}
        >
          <RestaurantList
            onClick={handleMarkerClick}
            onHomeClick={handleHomeClick}
          />
        </div>
      ) : null}

      {!showHomeScreen && !selectedRestaurant && !showRestaurantList ? (
        <div className="detail-logo map-logo">
          <div className="logo-container" onClick={handleHomeClick}>
            <img
              className="icon-img"
              alt="melting-spots"
              src="../images/melting-wide.png"
            ></img>
          </div>
        </div>
      ) : null}

      {!selectedRestaurant && showHomeScreen === false ? (
        <div className="toggle-buttons">
          <button
            className={`map-button toggle-button ${
              !showRestaurantList ? "active" : ""
            }`}
            onClick={handleStartClick}
          >
            Map
          </button>
          <button
            className={`list-button toggle-button ${
              showRestaurantList ? "active" : ""
            }`}
            onClick={toggleRestaurantList}
          >
            List
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default App;
