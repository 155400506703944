import RestaurantCard from "./RestaurantCard";
import "./RestaurantList.css";
import restaurants from "../restaurants.json";

const RestaurantList = ({ onClick, onHomeClick }) => {
  
  const handleLogoClick = () => {
    if (onHomeClick) {
      onHomeClick();
    }
  };

  const handleClick = (restaurant) => {
    if (onClick) {
      onClick(restaurant);
    }
  };

  return (
    <div className="restaurant-list">
      <div className="detail-logo" onClick={handleLogoClick}>
        <div className="logo-container">
        <img
          className="icon-img"
          alt="melting-spots"
          src="../images/melting-wide.png"
        ></img>
        </div>
      </div>
      <div className="restaurant-list-div">
      {restaurants.sort((a, b) => a.number - b.number).map((restaurant) => (
        <RestaurantCard 
        key={restaurant.number} 
        restaurant={restaurant} 
        onClick={handleClick}
        />
      ))}
    </div>
    </div>
  );
};

export default RestaurantList;
